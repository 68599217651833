import 'simplebar';

import ResizeObserver from 'resize-observer-polyfill';
window.ResizeObserver = ResizeObserver;
const $ = window.$;

$(() => {
	$('.mobile-navigation > .menu-item').each((idx, item) => {
		const block = $(item);
		if (block.hasClass('menu-item-has-children')) return;
		block.append('<ul class="sub-menu"></ul>');
	});
});

$(() => initSliders());
$(window).on('resize', () => initSliders());

function initSliders() {
	$('.block-highlights').each((idx, item) => {
		const slider = $(item).find('.slider');
		if (window.matchMedia('(max-width: 900px)').matches) {
			slider.slick({
				variableWidth: true,
			});
		} else if (slider.hasClass('slick-initialized')) {
			slider.slick('unslick');
		}
	});
}

const observerCallback = (entries) => {
	entries.forEach((entry) => {
		if (entry.isIntersecting) {
			entry.target.classList.add('is-visible');
		}
	});
};

const observer = new IntersectionObserver(observerCallback, {
	threshold: 0.15,
});
const targets = document.querySelectorAll('.show-on-scroll');

targets.forEach(function (target) {
	observer.observe(target);
});

function onScroll() {
	const st =
		document.body.scrollTop || document.documentElement.scrollTop || 0;
	if (st >= 60) {
		$('body').addClass('scrolled');
	} else {
		$('body').removeClass('scrolled');
	}
}
$(window).scroll(function () {
	onScroll();
});
onScroll();
